'use client';

import posthog from 'posthog-js';
import { PostHogProvider as PHOriginalProvider } from 'posthog-js/react';
import configuration from '~/configuration';

if (typeof window !== 'undefined') {
  const environment: string = process.env.NEXT_PUBLIC_APP_ENV || 'Development';

  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: configuration.site.siteUrl + '/ingest-ph',
    ui_host: process.env.NEXT_PUBLIC_POSTHOG_HOST!,
    autocapture: false,
    capture_pageview: true,
    loaded: (posthog) => {
      // if (configuration.appEnv !== 'production') posthog.opt_out_capturing();
      posthog.register({
        environment,
      });
    },
  });
}

export default function PostHogProvider({ children }: { children: React.ReactNode }) {
  return <PHOriginalProvider client={posthog}>{children}</PHOriginalProvider>;
}
